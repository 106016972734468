export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  let header = {
    'Accept': 'application/json',
    'Authorization': '',
  };
  if (user && user.accessToken) {
    // For Spring Boot back-end
    header.Authorization = "Bearer "+user.accessToken;
    
    return header;

    // for Node.js Express back-end
    //return { "x-access-token": user.accessToken };
  } else {
    return header;
  }
}
