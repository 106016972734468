import { 
    SET_MESSAGE,
    GET_PACKAGES,
    ORDER_SUCCESS,
    ORDER_FAIL,
    PROFILE_SUCCESS,
    PROFILE_FAIL,

  } from "./types";
  
  import UserService from "../services/user.service"; 
  export const getPackage = (payload) => {
    return (dispatch) => {
        UserService.getPackages(payload).then((res) => {
          dispatch({
            type: GET_PACKAGES,
            payload: res,
          }); 
          
          return Promise.resolve();
        })
        .catch((err) =>{ 
          console.log('ERROR', err);
            return Promise.reject();
        });
    };
  }; 
 
export const orderSuccessAction = (payload) => (dispatch) => {
  return UserService.orderPlace(payload).then(
    (data) => {
      dispatch({
        type: ORDER_SUCCESS,
        payload: data,
      });
      console.log("Order API Success");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ORDER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const getProfileAction = (payload) => (dispatch) => {
  return UserService.getProfile(payload).then(
    (data) => {
      dispatch({
        type: PROFILE_SUCCESS,
        payload: data,
      });
      console.log("Order API Success");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PROFILE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
