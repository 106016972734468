import React, { useState, useRef } from 'react';
import { Navigate, useNavigate  } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Styles from './Register.module.css'
import { useDispatch, useSelector } from "react-redux";
import { accountVerifyAction } from "../actions/auth";
const AccountVerify = () => {
   const dispatch = useDispatch();
    let navigate = useNavigate();
      const { message } = useSelector(state => state.message);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const { isLoggedIn } = useSelector(state => state.auth);
      const { isVerified,status } = useSelector(state => state.auth.user);
     
      const ragisterdata = {};
      const defaultValue = {
        otp: '', 
      }

      const validationSchema = yup.object().shape({ 
        otp: yup.number().required("Please Enter OTP"), 
      })
 
    const handleSubmit = async (values) => {
        console.log('vlaues', values)
        values.email = user.email;
        await dispatch(accountVerifyAction(values)).then((res) => {
           
            navigate("/home");
           
          //window.location.reload();
          console.log("test",res);
        })
        .catch(() => {
          setLoading(false);
        });
         

   }
   
    return (
        <div className="col-md-12">
            <div className="card card-container">
                <div className="card-header">
                    <h4>Account Verify</h4>
                </div>
                 <Formik initialValues={defaultValue} validationSchema={validationSchema} onSubmit={handleSubmit} >
                      <Form className={Styles['FormContainer']}>
                        <div>
                          <label htmlFor="" className={Styles['InputLabel']} > Enter Your OTP </label>
                         
                          <Field type='text' name='otp' placeholder='Enter Your OTP' className={Styles['inputField']} />
                          <p><ErrorMessage name='otp' />{ragisterdata.error && ragisterdata.error.data && ragisterdata.error.data.otp}</p>

                        </div>
                         <div>
                          <button type='submit' className="btn btn-primary">Verify</button>
                        </div> 
                        {message && (
                            <div className="form-group">
                              <div className="alert alert-danger" role="alert">
                                {message}
                              </div>
                            </div>
                          )}
                      </Form>
                    </Formik> 
            </div>
        </div>
    )
}

export default AccountVerify