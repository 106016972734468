import axios from "axios";

//const API_URL = "http://localhost:8080/api/auth/";
const API_URL = "https://www.write.startdesigns.com/api/";
//const API_URL = "http://write-api.local/api/"; 

 
const register = (payload) => {
  return axios
    .post(API_URL + "register", payload)
    .then((response) => { 
      let data  = response.data.data; 
      if (data.accessToken) {
        localStorage.setItem("user", JSON.stringify(data));
      }
       
      return data;
    });
};

/*const register = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "register", payload)
      .then((response) => {

        let data  = response.data.data; 
//       if (data.accessToken) {
//         localStorage.setItem("user", JSON.stringify(data));
//       }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};*/

 

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
     
      let data  = response.data.data;
      
      if (data.accessToken) {
        localStorage.setItem("user", JSON.stringify(data));
      }

      return data;
    });
};

const accountVerify = (parma) => {
  
  return axios
    .post(API_URL + "verify-otp",parma)
    .then((response) => { 
      let data  = response.data.data; 
      if (data.accessToken) {
        localStorage.setItem("user", JSON.stringify(data));
      } 
      return data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  accountVerify,
  login,
  logout, 
};
