import React, { useState, useEffect } from "react";
import { Navigate, useNavigate  } from 'react-router-dom';
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { useDispatch, useSelector } from "react-redux";
import  {getProfileAction}  from "../actions/getPackages";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


const Profile = () => {
  const dispatch = useDispatch();
  const { userProfile: currentUser } = useSelector((state) => state.auth); 
   let navigate = useNavigate();
   const [loading, setLoading] = useState(false);
    useEffect(()=>{
      dispatch(getProfileAction()); 
    },[]) 

  return (
    <div className="container">
      { currentUser && 
        <div>
          <header className="jumbotron">
            <h3>
              <strong>{currentUser.name}</strong> Profile
            </h3>
          </header> 
          <p>
            <strong>First Name:</strong> {currentUser.first_name}
          </p>
          <p>
            <strong>Last Name:</strong> {currentUser.last_name}
          </p> 
          <p>
            <strong>Email:</strong> {currentUser.email}
          </p>
          <p>
            <strong>Phone:</strong> {currentUser.phone}
          </p>
          <p>
            <strong>Word Limit:</strong> {currentUser.count_limit}
          </p>
        </div>
      }
       
    </div>
  );
};

export default Profile;
