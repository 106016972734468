import React from 'react';
import { Route, Navigate  } from 'react-router-dom'; 

export const PrivateRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  var authed =  false // isauth() returns true or false based on localStorage 
  var navigateRoute = '/Home';
 
  if(user){
     console.log('preivate',user.isVerified);
    if(user.isVerified !== 1){
      navigateRoute = '/account-verify'
    }else{
      authed = true;
    }
  }
  
  return authed ? children : <Navigate to={navigateRoute} />;
}