import {
    GET_PACKAGES,
    ORDER_SUCCESS
  } from "../actions/types";




  
const intialState = {
    package: [],
  
  };
  
  const getPackage = function (state = intialState, action) {
//   const { type, payload } = action;

    switch (action.type) {
      case GET_PACKAGES :{
        return {
          ...state,
          package: action.payload,
        };
      }
      case ORDER_SUCCESS :{
        return {
          ...state,
          activePackage: action.payload,
        };
      }
     
     
      default:
        return state;
    }
  };
  

  export default getPackage;