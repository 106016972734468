 
import React, { useState, useEffect, useCallback,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Styles from './Register.module.css'
import { Navigate, useNavigate  } from 'react-router-dom';
// import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/auth";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
 

const Register = () => {
  
  const dispatch = useDispatch();
  let navigate = useNavigate();

  
  // *************************formik defoult feilds *******
  const { message } = useSelector(state => state.message);
    const [loading, setLoading] = useState(false);
  const ragisterdata = useSelector((state)=>state.auth);

    const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const defaultValue = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    gander: '',
    password: '',
    c_password: ''

  }
  useEffect(() => { 
    if (isLoggedIn && currentUser) { 
          navigate("/account-verify");  
    }
  }, [currentUser]);

  const validationSchema = yup.object().shape({
    first_name: yup.string().required('please Enter you name '),
    last_name: yup.string().required('please Enter you name '),
    email: yup.string().email().required("Please Enter Email"),
    password: yup.string().min(6).required("Please Enter Password"),
    c_password: yup.string().required("Please Enter Conform Password").oneOf([yup.ref('password'), null, "not match"]),
    gander: yup.string().oneOf(["male", "female"], "Required").required("Required"),
    phone: yup.number().min(10).required('Enter Valid Number')
  })


  const handleSubmit = async (values) => {
    console.log('vlaues', values)
      setLoading(true);
    await dispatch(register(values)).then(() => {
          console.log('redirect');
             setLoading(false);
            navigate("/account-verify");
           
         // window.location.reload();
        })
        .catch(() => {
           console.log('register-catch');
          setLoading(false);
        });

  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
      
        <Formik initialValues={defaultValue} validationSchema={validationSchema} onSubmit={handleSubmit} >
          <Form className={Styles['FormContainer']}>
            <div>
              <label htmlFor="" className={Styles['InputLabel']} > Enter Your First Name </label>

              <Field type='text' name='first_name' placeholder='Enter Your First Name' className={Styles['inputField']} />
              <p><ErrorMessage name='first_name' />{ragisterdata.errors && ragisterdata.errors.first_name}</p>

            </div>
            <div>
              <label htmlFor="" className={Styles['InputLabel']}>Enter Your Last Name</label>

              <Field type='text' name='last_name' placeholder='Enter Your Last Name' className={Styles['inputField']} />
              <p><ErrorMessage name='last_name' />{ragisterdata.errors && ragisterdata.errors.last_name}</p>

            </div>
            <div>
              <label htmlFor="" className={Styles['InputLabel']}> Enter Your Email </label>

              <Field type='email' name='email' placeholder='Enter Your Email' className={Styles['inputField']} />
              <p><ErrorMessage name='email' />{ragisterdata.errors && ragisterdata.errors.email}</p>

            </div>
            <div>
              <label htmlFor="" className={Styles['InputLabel']}> Enter Your Phone Number </label>

              <Field type='number' name='phone' placeholder='Enter Your Number' className={Styles['inputField']} />
              <p><ErrorMessage name='phone' />{ragisterdata.errors && ragisterdata.errors.phone}</p>

            </div>


            <div>
              <label htmlFor="" className={Styles['InputLabel']}> Enter Your Password </label>

              <Field type='password' name='password' placeholder='Enter Your Password' className={Styles['inputField']} />
              <p><ErrorMessage name='password' />{ragisterdata.errors && ragisterdata.errors.password}</p>

            </div>
            <div>
              <label htmlFor="" className={Styles['InputLabel']}> Enter Your Conform Password </label>

              <Field type='password' name='c_password' placeholder='Enter Your Conform Password' className={Styles['inputField']} />
              <p><ErrorMessage name='c_password' />{ragisterdata.errors && ragisterdata.errors.c_password}</p>

            </div>
            <div className={Styles['GenderDiv']}>
              <label htmlFor="gender"> Gender </label>
              <label htmlFor="male">
                <Field type='radio' name='gander' value='male' /> male
              </label>
              <label htmlFor="female">
                <Field type='radio' name='gander' value='female' /> female
              </label>
              <p><ErrorMessage name='gander' /> {ragisterdata.errors && ragisterdata.errors.gander}</p>

            </div>
            <div>
             
              <button type='submit' className="btn btn-primary btn-block" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Login</span>
            </button>
            </div>

          </Form>
        </Formik>

      </div>
    </div>
  );
};

export default Register;
