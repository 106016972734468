import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PROFILE_SUCCESS,
  PROFILE_FAIL
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: [],errors:[] };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        errors:payload
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case VERIFY_FAIL:
      return {
        ...state, 
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case PROFILE_SUCCESS:
      return {
        ...state, 
        userProfile: payload,
      };
    case PROFILE_FAIL:
      return {
        ...state, 
        userProfile: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: [],
        errors:[],
      };
    default:
      return state;
  }
}
