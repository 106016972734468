import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://www.write.startdesigns.com/api/";

//const API_URL = "http://write-api.local/api/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

 

const getPackages = (payload) => {
  return axios.get(API_URL + "packages" ).then((res)=>{
    let data = res.data.data;  
    return data;
    
  })
};
const getProfile = (payload) => {
   return axios.get(API_URL + "user-profile", { headers: authHeader() }).then((res)=>{
    let data = res.data.data;  
    return data;
    
  })
};
const orderPlace = (parma) => {
   
  return axios
    .post(API_URL + "suscription/order-place", parma,{ headers: authHeader() })
    .then((response) => { 
      let data  = response.data.data; 
      return data;
    });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getPackages,
  orderPlace,
  getProfile
};