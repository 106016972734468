import React, { useState, useEffect } from "react";
import { Navigate, useNavigate  } from 'react-router-dom';
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { useDispatch, useSelector } from "react-redux";
import  {getPackage,orderSuccessAction}  from "../actions/getPackages";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Packages = () => {
  const dispatch = useDispatch();
   let navigate = useNavigate();
   const [loading, setLoading] = useState(false);
  useEffect(()=>{
    dispatch(getPackage());

  },[])
  const packagesData = useSelector((state)=>state?.getPackage)
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const handleSubscribe = (e,code) => { 
      e.preventDefault(); 
        setLoading(true);
         if(isLoggedIn){
          if(currentUser.isVerified){
            dispatch(orderSuccessAction({'package_code':code}))
            .then((res) => {  
                navigate("/profile");  
            })
            .catch(() => { 
              setLoading(false);
            });
          }else{
            navigate("/account-verify"); 
          }
        }else{
          navigate("/login"); 
        }
    } 
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Packages</h3>
      </header>

      <section>
        <div className="row">
           {
                packagesData?.package.map((obj, index) => (
                    <div className="col-sm-4">
                       <Card>
                          <Card.Header>{obj.name}</Card.Header>
                          <Card.Body> 
                              <Card.Text>
                                  {obj.features}
                                  {obj.count_limit}
                              </Card.Text>

                              <p>price: {obj.price}</p>
                             
                          </Card.Body>
                          <Card.Footer className="text-muted"> 
                           <Button variant="primary" onClick={(e) => handleSubscribe(e,obj.package_code)} disabled={loading}>
                              {!isLoggedIn ? 'Login' : 'Subscribe Now'}
                            </Button>
                           </Card.Footer>
                      </Card>
                      </div>
                            
                ))
                }
         
        </div>
      </section>

    </div>
  );
};

export default Packages;
